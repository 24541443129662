.card {
  .card-body {
    .card-title {
      span {
        font-size: 20px;
        line-height: 38px;
      }

      .card-icon {
        font-size: 20px;
        line-height: 38px;
        margin-left: 1.75rem;
      }
    }
  }
}
