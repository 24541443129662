$special-blue: #0099ff;
$light-special-blue: rgba(0, 153, 255, .75);
$light-grey: #ABABAB;
$grey: #707070;
$dark-red: #cc0000;
$lime: #c3e6cd;
$light-green: #b1dfbb;
$black: #000000;
$white: #FFFFFF;
$text-color: #000000;
$light-black: #00000029;

.text-grey {
  color: $grey;
}

.text-light-grey {
  color: $light-grey;
}
