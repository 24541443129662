:root {
    font-size: 14px;
}

/* Fix scrolling when using a sticky header by supplying it's height for "top" attribute. */
:target {
    display: block;
    position: relative;
    top: -0px; /* Use -50px for a sticky header height of 50px. */
    visibility: hidden;
}

/* Recolor text selection. */
::selection,
::-moz-selection,
::-webkit-selection {
    background-color: blue;
}

*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

html,
body {
    min-height: 100vh;
    margin: 0;
    padding: 0;
}

/* Force hand cursor over clickable items. Required for iOS on all interactive elements. */
a[href],
input[type='submit'],
input[type='image'],
label[for],
select,
button,
.pointer,
.clickable {
    cursor: pointer;
}

/* Remove outline. This will make website navigation via keyboard pretty much impossible. */
*:active,
*:hover,
*:focus {
    outline: none;
}

p {
    font-size: inherit;
    line-height: initial;
    font-weight: 300;
    margin-bottom: 1.75rem;
}

// Mobile
@media (max-width: 768px) {
    :root {
        font-size: 16px;
    }
}
