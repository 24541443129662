////////////
// Custom //
////////////
.alert {
  margin-bottom: 0;
  border: none;
  border-radius: 0;
  text-align: center;
  color: $white !important;
  padding: .5rem 1.75rem;

  &.alert-success {
    background-color: #468a26;
  }

  &.alert-error,
  &.alert-danger {
    background-color: #721c24;
  }

  &.alert-info {
    background-color: #0c5460;
  }
}
