/////////////////
// MDBootstrap //
/////////////////
@import '~mdbootstrap-pro/scss/free/footers';

////////////
// Custom //
////////////
footer {
  background-color: $light-grey;

  .container-fluid {
    font-size: 1.143rem;

    .row {
      .col-md-2 {
        ul {
          li {
            text-transform: uppercase;
            margin-top: 1.5rem;
            margin-bottom: 1.5rem;
          }
        }
      }
    }
  }

  &.page-footer {
    .footer-copyright {
      background-color: $light-grey;
    }
  }
}

// Desktop
@media (min-width: 768px) {
  footer {
    .container-fluid {
      .row {
        padding-left: 5rem;
      }
    }
  }
}

// Mobile
@media (max-width: 768px) {
  footer {
    .container-fluid {
      font-size: 1rem;
    }
  }
}
