@import '~mdbootstrap-pro/scss/pro/_accordion-basic';

.accordion {
  &#mobileAppointmentList {
    .card {
      border-radius: 0.25rem;

      .card-header {
        padding: 1rem;

        h5 {
          line-height: 2rem;

          a {
            color: $black;
            font-size: 1rem;

            &.icon {
              font-size: 1.5rem;
            }
          }

          i {
            &.rotate-icon {
              font-size: 1.5rem;
              color: $primary-color;
            }
          }
        }
      }

      .card-body {
        color: $black;
        padding: 0 1rem 1rem;

        a {
          &.icon {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}