// Global
.tooltip {
  .tooltip-inner {
    background-color: $white;
    color: $text-color;
    box-shadow:
            0 5px 11px 0 rgb(0 0 0 / 18%),
            0 4px 15px 0 rgb(0 0 0 / 15%);
  }
}
