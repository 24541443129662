// Global
a {
  &.disabled,
  &:disabled {
    color: $grey;

    &:hover {
      color: $grey;
    }
  }

  &.dropdown-item,
  &.btn {
    &:hover {
      text-decoration: none !important;
    }
  }

  &.text-primary {
    &:hover,
    &:focus {
      color: $primary-color !important;
    }
  }

  &.unset {
    height: unset !important;
    padding-left: unset !important;
    font-size: unset !important;
    line-height: unset !important;
  }

  &.secondary {
    color: $grey;

    &:hover {
      color: $primary-color;
    }
  }

  &.icon {
    font-size: 1.143rem;

    &:hover {
      text-decoration: none !important;
    }
  }
}

.collapsible {
  a {
    &:hover {
      text-decoration: none !important;
    }
  }
}

// Desktop
@media (min-width: 768px) {
  a {
    &:hover {
      text-decoration: underline !important;
    }
  }
}

// Mobile
@media (max-width: 768px) {
  a {
    &#appointment-list-button {
      font-size: 1.5rem;
    }
  }
}
