body {
  color: $esd-default-font-color;
  background-color: $esd-default-background-color;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $black;
  }
}

#app {
  min-height: 100vh;
  margin: 0;
  padding: 0;

  main {
    .container {
      .inner-nav {
        .nav-item {
          &.active {
            border-bottom: 2px solid $special-blue;
          }

          &:hover {
            transition: none;
            text-decoration: none !important;
            border-bottom: 2px solid $special-blue;
          }
        }
      }

      .tooltip-container {
        &:hover {
          cursor: help;
        }
      }

      .underline {
        border-bottom: 1px solid $grey-lighten-1;
      }

      .step-disabled {
        opacity: .20;
      }

      .second-step {
        #second-step {
          .day-select {
            margin-bottom: 2rem;
          }
        }
      }

      .appointment-special-select,
      .day-select {
        .form-check {
          .form-check-input {
            &+label {
              color: $primary-color;
              padding-left: .5rem;
              padding-right: .5rem;
              padding-bottom: 1.8rem;
              border-bottom: 1px solid transparent;

              &::before {
                content: unset
              }
            }

            &:checked {
              &+label {
                border-bottom: 1px solid $primary-color;

                &::after {
                  content: unset;
                }
              }
            }
          }

          .form-check-label {
            padding-left: 0;
          }
        }
      }

      .speaking-time-table,
      .breaks-time-table,
      .time-select {
        .time-table {
          tr {
            &.occupied,
            &.impossible {
              background-color: $light-grey;
            }

            &.break {
              color: $white;
              background-color: $grey;
            }

            &.appointment {
              color: $white;
              background-color: $light-special-blue;
            }

            &.selected {
              color: $white;
              background-color: $primary-color;
            }
          }
        }
      }

      .last-step {
        .submit-button {
          position: relative;
          min-height: 3rem;
        }
      }

      #special_appointment_student {
        optgroup {
          display: none;
        }
      }
    }
  }
}

.picker__box {
  .picker__header {
    .picker__nav--prev,
    .picker__nav--next {
      &::before {
        font-family: "Font Awesome 5 Pro";
      }
    }
  }
}

.dataTables_wrapper {
  width: 100%;
}

table {
  .dataTable {
    thead {
      .sorting,
      .sorting_asc,
      .sorting_desc,
      .sorting_asc_disabled,
      .sorting_desc_disabled {
        &::before,
        &::after {
          font-family: "Font Awesome 5 Pro" !important;
        }
      }
    }

    &.table-sm {
      thead {
        .sorting,
        .sorting_asc,
        .sorting_desc,
        .sorting_asc_disabled,
        .sorting_desc_disabled {
          &::before,
          &::after {
            bottom: 0.4em !important;
          }
        }
      }
    }
  }
}

.dropdown-content {
  overflow-y: scroll !important;
}

// Desktop
@media (min-width: 768px) {
  #app {
    main {
      .container {
        .first-step {
          #teacher_details {
            > .row {
              position: absolute;
              bottom: 0;
              width: 100%;
              margin-bottom: 2rem;
            }
          }
        }

        .last-step {
          .submit-button {
            .form-group {
              position: absolute;
              bottom: 0;
              right: 0;
            }
          }
        }

        #appointment-list,
        #breaks-list,
        #appointment-special-list {
          .day-select,
          .appointment-special-select {
            padding-bottom: 1rem;
          }
        }
      }
    }
  }
}
