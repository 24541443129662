/////////////////
// MDBootstrap //
/////////////////
@import '~mdbootstrap-pro/scss/free/forms';
@import '~mdbootstrap-pro/scss/pro/forms';
@import '~mdbootstrap-pro/scss/pro/checkbox';
@import '~mdbootstrap-pro/scss/pro/material-select';
@import '~mdbootstrap-pro/scss/pro/file-input';
@import '~mdbootstrap-pro/scss/pro/radio';

////////////
// Custom //
////////////
.md-form {
  margin-top: 2rem;
  margin-bottom: 2rem;

  input:not([type]),
  input[type="text"]:not(.browser-default),
  input[type="password"]:not(.browser-default),
  input[type="email"]:not(.browser-default),
  input[type="url"]:not(.browser-default),
  input[type="time"]:not(.browser-default),
  input[type="date"]:not(.browser-default),
  input[type="datetime"]:not(.browser-default),
  input[type="datetime-local"]:not(.browser-default),
  input[type="tel"]:not(.browser-default),
  input[type="number"]:not(.browser-default),
  input[type="search"]:not(.browser-default),
  input[type="phone"]:not(.browser-default),
  input[type="search-md"],
  textarea.md-textarea {
    color: $black;
    font-weight: $font-weight-light;

    &:focus:not([readonly]) {
      + label {
        + small {
          color: $input-md-focus-color !important;
        }
      }
    }
  }

  textarea.md-textarea {
    box-shadow: none !important;

    &:focus:not([readonly]) {
      border: 1px solid $input-md-focus-color !important;
    }
  }

  label {
    &.active {
      font-size: 12px !important;
      transform: translateY(-8px) !important;

      &.required {
        &:after {
          color: $primary-color;
        }
      }
    }

    &.required {
      &:after {
        content: ' *' !important;
        color: $grey-darken-1;
        position: unset !important;
        top: 0 !important;
        display: inline-block !important;
        opacity: unset !important;
        margin-left: 5px;
      }
    }
  }

  .form-control {
    margin: 0 0 .25rem 0;

    &.is-invalid {
      border-color: $input-error-color !important;
    }

    &.select-dropdown {
      &[readonly] {
        color: $black;
      }
    }

    &:disabled,
    &[readonly] {
      color: $grey-lighten-1;
      background-color: $white !important;
      border-bottom: 1px solid $grey-lighten-1;
    }
  }

  &.select-wrapper {
    &.mdb-select {
      span {
        &.caret {
          right: 10px;
        }
      }

      .select-dropdown {
        &.form-control {
          background-color: transparent !important;

          &:disabled {
            color: rgba(0, 0, 0, 0.3);
          }

          &.active {
            + .caret {
              transform: rotate(-180deg);
            }
          }
        }
      }

      .dropdown-content {
        &.select-dropdown {
          top: 2.5rem !important;
        }
      }
    }
  }

  .form-text {
    &.text-muted {
      font-weight: 300;
    }
  }

  i {
    &.password-icon  {
      position: absolute;
      top: 1rem;
      right: 1rem;

      &:hover {
        cursor: pointer;
      }
    }
  }

  textarea {
    &.md-textarea {
      resize: none;
      border-bottom-color: $grey;
      padding: 3px;

      &+label {
        top: -1rem;

        &.active {
          color: $primary-color;
        }
      }
    }
  }

  span {
    &.character-counter {
      float: right;
      font-size: 12px;
    }
  }
}

// Mobile
@media (max-width: 768px) {
  .md-form {
    label {
      &.active {
        font-size: 14px !important;
        transform: translateY(-10px) !important;
      }
    }

    &.select-wrapper {
      &.mdb-select {
        .dropdown-content {
          li {
            line-height: 1.7rem;
          }
        }
      }

      > label {
        &.mdb-main-label {
          &.active {
            font-size: unset;
          }
        }
      }
    }
  }
}
