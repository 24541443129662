/////////////////
// MDBootstrap //
/////////////////
@import '~mdbootstrap-pro/scss/free/buttons';
@import '~mdbootstrap-pro/scss/pro/buttons';

////////////
// Custom //
////////////
.btn {
  font-size: 1rem;
  border-radius: .286rem;
  border-width: 1px !important;
  box-shadow: none;
  width: 22rem;

  &:hover,
  &:focus,
  &:active {
    box-shadow: none !important;
  }

  .btn-choose {
    width: 100%;
    background-color: $lime;
    border: none;
    padding: 2.8%;

    &:hover,
    &:focus {
      background-color: $light-green;
    }
  }

  &.btn-primary {
    border: 1px solid $primary-color;
  }

  &.btn-link {
    color: $grey !important;

    &:hover {
      text-decoration: underline;
    }
  }

  &.disabled,
  &:disabled {
    opacity: unset;
  }
}

.black-skin {
  .btn-outline-primary {
    border-width: 1px !important;
  }
}

// Mobile
@media (max-width: 768px) {
  html,
  body {
    .btn-choose {
      padding: 6.3%;
    }
  }

  .btn {
    padding: .857rem 1.5rem !important;

    &.btn-primary {
      &:hover {
        color: $white;
        background-color: $primary-color !important;
        box-shadow: none !important;
      }

      &#appointment-list-button {
        width: 100%;
        margin-left: 0;
      }
    }
  }
}