/////////////////
// MDBootstrap //
/////////////////
@import '~mdbootstrap-pro/scss/free/modals';

////////////
// Custom //
////////////
// General
.modal {
  .modal-dialog {
    margin: 0;

    .modal-content {
      border-radius: 1.143rem;
      box-shadow: none;

      .modal-header {
        border-bottom: none;
        padding: 2rem 2rem 1rem;

        .close {
          i {
            color: $black;

            &:hover {
              color: $black;
            }
          }
        }
      }

      .modal-body {
        padding: 0 2rem 1rem;
      }

      .modal-footer {
        border-top: none;
      }
    }
  }

  &.show {
    .modal-dialog {
      margin: 0;
    }
  }

  .btn {
    text-transform: none;
    width: auto;
  }
}

#consentModal {
  .modal-header {
    border-bottom: 0;
    padding-bottom: 0;
  }

  .modal-footer {
    border-top: 0;
    padding-top: 0;
  }

  .consent-links {
    font-size: .75rem;
    color: $primary-color;

    & > .consent-link {
      color: $primary-color;

      &:hover,
      &:active,
      &:focus {
        color: $primary-color;
      }
    }
  }
}

// Desktop
@media (min-width: 768px) {
  .modal {
    .modal-dialog {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) !important;

      .modal-content {
        .modal-footer {
          padding: 1rem 2rem 1rem;
        }
      }
    }

    &.show {
      .modal-dialog {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) !important;
      }
    }
  }
}

// Mobile
@media (max-width: 768px) {
  .modal {
    .modal-dialog {
      .modal-content {
        height: 100vh;
        border-radius: 0;

        .modal-footer {
          padding: 1rem 1rem 1rem;
        }
      }
    }
  }
}
