h1 {
  font-weight: bolder;
  margin-bottom: 2rem;
}

h3 {
  font-size: 1.714rem;
  font-weight: $font-weight-normal;
  margin-bottom: 1.643rem;
}

h6 {
  font-size: 1.143rem;
  font-weight: $font-weight-normal;
  margin-bottom: 1.5rem;
}

small,
.small {
  font-size: 75%
}

.font-smaller {
  font-size: .75rem;
}

// Mobile
@media (max-width: 768px) {
  h3 {
    font-size: 1.25rem;
    margin-bottom: 1.143rem;
  }

  small,
  .small {
    font-size: 80%;
  }
}
