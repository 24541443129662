/////////////////
// MDBootstrap //
/////////////////
@import '~mdbootstrap-pro/scss/free/navbars';
@import '~mdbootstrap-pro/scss/pro/navbars';

////////////
// Custom //
////////////
header {
  background-color: $light-grey;

  .navbar {
    font-size: 1.143rem;
    padding-top: 0;
    padding-bottom: 0;

    .navbar-brand {
      padding: .8rem 0;

      img {
        margin-right: 30%;
        max-height: 40px;
      }
    }

    .navbar-toggler {
      .animated-icon {
        width: 30px;
        height: 20px;
        position: relative;
        margin: 0;
        -webkit-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
        -webkit-transition: .5s ease-in-out;
        -moz-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;
        cursor: pointer;

        span {
          background-color: $white;
          display: block;
          position: absolute;
          height: 3px;
          width: 100%;
          border-radius: 9px;
          opacity: 1;
          left: 0;
          -webkit-transform: rotate(0deg);
          -moz-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          transform: rotate(0deg);
          -webkit-transition: .25s ease-in-out;
          -moz-transition: .25s ease-in-out;
          -o-transition: .25s ease-in-out;
          transition: .25s ease-in-out;

          &:nth-child(1) {
            top: 0;
          }

          &:nth-child(2),
          &:nth-child(3) {
            top: 10px;
          }

          &:nth-child(4) {
            top: 20px;
          }
        }

        &.open {
          span {
            &:nth-child(1) {
              top: 11px;
              width: 0%;
              left: 50%;
            }

            &:nth-child(2) {
              -webkit-transform: rotate(45deg);
              -moz-transform: rotate(45deg);
              -o-transform: rotate(45deg);
              transform: rotate(45deg);
            }

            &:nth-child(3) {
              -webkit-transform: rotate(-45deg);
              -moz-transform: rotate(-45deg);
              -o-transform: rotate(-45deg);
              transform: rotate(-45deg);
            }

            &:nth-child(4) {
              top: 11px;
              width: 0%;
              left: 50%;
            }
          }
        }
      }
    }

    .navbar-collapse {
      .navbar-nav {
        .nav-item {
          margin: 0 2.5rem;

          &.active {
            .nav-link {
              background-color: unset;
              border-bottom: 6px solid $white;
            }
          }

          .nav-link {
            padding: 2rem 1.25rem 1rem;
            border-bottom: 6px solid transparent;

            &:hover {
              transition: none;
              text-decoration: none !important;
              border-bottom: 6px solid $white;
              color: $white;
            }
          }
        }
      }
    }
  }
}

// Desktop
@media (min-width: 768px) {
  header {
    .navbar {
      .navbar-brand {
        img {
          margin-left: 30%;
        }
      }
    }
  }
}

// Mobile
@media (max-width: 768px) {
  header {
    &.header {
      .navbar {
        .navbar-collapse {
          position: absolute;
          top: 3.5rem;
          left: 0;
          width: 100%;
          background-color: $light-grey;

          .navbar-nav {
            .nav-item {
              margin: 0 2.5rem;

              &.active {
                .nav-link {
                  border-bottom-width: 3px;
                }
              }

              .nav-link {
                border-bottom-width: 3px;

                &:hover {
                  border-bottom-width: 3px;
                }
              }
            }
          }
        }
      }
    }
  }
}
