table {
  &.table {
    background: $white 0 0 no-repeat padding-box;
    box-shadow: 0 1px 3px $light-black;
    border-radius: 4px;

    thead,
    tbody {
      tr {
        &:first-child {
          padding-left: 30px;
        }

        &:last-child {
          padding-right: 30px;
        }
      }
    }

    thead {
      th {
        border-top: none !important;
        border-width: thin;
        font-weight: $font-weight-bold;
        color: $black;
      }
    }

    tbody {
      th {
        &.th-no-style {
          font-weight: 300;
        }
      }
    }

    th,
    td {
      padding: 1.25rem;
    }
  }
}