.w-5 {
  width: 5% !important;
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.w-25 {
  width: 25% !important;
}

//Desktop
@media (min-width: 768px) {
  .mt-5,
  .my-5 {
    margin-top: 4rem !important;
  }

  .mt-md-5,
  .my-md-5 {
    margin-top: 4rem !important;
  }

  .mb-5,
  .my-5 {
    margin-bottom: 4rem !important;
  }
}

// Mobile
@media (max-width: 768px) {
  .w-sm-100 {
    width: 100% !important;
  }
}